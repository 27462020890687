// src/pages/AboutUs.js
import React from 'react';
import styles from './AboutUs.module.css';
import heroBackground from '../assets/aboutus.webp'; // Import the image

const AboutUs = () => {
  return (
    <div className={styles.aboutUs}>
      <img 
        src={heroBackground} 
        alt="About Us" 
        className={styles.aboutUsImage}
      />
      <div className={styles.aboutUsText}>
        <h2>About Us</h2>
        <p>
          Welcome to Razor Olympus! We are passionate about delivering the finest cutlery to our customers. Our dedication to quality, craftsmanship, and customer satisfaction sets us apart in the industry. From everyday kitchen essentials to specialized tools, we strive to meet the diverse needs of our clientele.
        </p>
        <p>
          Our team at Razor Olympus is committed to innovation and excellence. We continuously seek out the best materials and designs to bring you products that enhance your culinary experience. Thank you for choosing Razor Olympus as your trusted source for top-quality cutlery.
        </p>
      </div>
    </div>
  );
};

export default AboutUs;
