import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import styles from './Hero.module.css';
import backgroundImage from '../assets/hero-background.webp'; // Ensure you have a background image
import { useSite } from '../context/SiteContext';

const Hero = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { slogan, info } = useSite();
  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
      const yOffset = -calculateFixedHeaderHeight(); // Adjust for fixed header height
      const yPosition = section.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: yPosition, behavior: 'smooth' });
    }
  };

  const calculateFixedHeaderHeight = () => {
    const headerHeight = 0.13 * window.innerHeight; // 13vh equivalent in pixels
    return headerHeight;
  };

  const handleButtonClick = (id, path) => {
    if (location.pathname === path) {
      scrollToSection(id);
    } else {
      navigate(path, { state: { scrollToSection: id } });
    }
  };

  React.useEffect(() => {
    if (location.state?.scrollToSection) {
      scrollToSection(location.state.scrollToSection);
    }
  }, [location]);

  return (
    <section className={styles.hero} style={{ backgroundImage: `url(${backgroundImage})` }}>
      <div className={styles.overlay}></div>
      <div className={styles.content}>
        <h1 className={styles.slogan}>{slogan}</h1>
        <p className={styles.description}>{info}</p>
        <div className={styles.buttons}>
          <button
            onClick={() => handleButtonClick('newproducts-section', '/')}
            className={styles.orderButton}
          >
            Shop Now
          </button>
          <button
            onClick={() => handleButtonClick('aboutus-section', '/')}
            className={styles.orderButton}
          >
            About Us
          </button>
          <button
            onClick={() => handleButtonClick('subscribe-section', '/')}
            className={styles.orderButton}
          >
            Subscribe
          </button>
        </div>
      </div>
    </section>
  );
};

export default Hero;
