import image1 from '../assets/image1.webp';
import image2 from '../assets/image2.webp';
import image3 from '../assets/image3.webp';
import image4 from '../assets/image4.webp';
import image5 from '../assets/image5.webp';
import image6 from '../assets/image6.webp';
import image7 from '../assets/image7.webp';
import image8 from '../assets/image8.webp';
import image9 from '../assets/image9.webp';

const products = [
  {
    id: 1,
    name: 'Mediterranean Country Cutting Boards',
    price: '41.99',
    image: image1,
    badge: 'New',
    description: 'Crafted from the finest Mediterranean wood, these cutting boards are perfect for all your culinary needs. Durable and stylish, they add a rustic charm to any kitchen.',
  },
  {
    id: 2,
    name: 'Custom Engraved Coaster Set',
    price: '11.99',
    image: image2,
    badge: 'New',
    description: 'Add a personal touch to your home with these custom engraved coasters. Made from high-quality materials, they protect your surfaces while adding a touch of elegance.',
  },
  {
    id: 3,
    name: 'Marble Wooden Butcher Board',
    price: '49.98',
    image: image3,
    badge: 'New',
    description: 'This exquisite butcher board combines the beauty of marble with the durability of wood. Ideal for preparing and presenting your finest cuts of meat.',
  },
  {
    id: 4,
    name: 'Personalized BBQ King/Queen Apron',
    price: '14.51',
    image: image4,
    badge: 'New',
    description: 'Show off your grilling skills with this personalized BBQ apron. Whether you are the king or queen of the grill, this apron ensures you cook in style.',
  },
  {
    id: 5,
    name: 'Personalized All Gray Canvas Apron',
    price: '18.50',
    image: image5,
    badge: 'Exclusive',
    description: 'Made from durable canvas, this personalized gray apron is perfect for any cooking enthusiast. Its sleek design and practical features make it a must-have in any kitchen.',
  },
  {
    id: 6,
    name: 'Summer Lemon Printed Cotton Apron',
    price: '45.97',
    image: image6,
    badge: 'Exclusive',
    description: 'Brighten up your kitchen with this summer lemon printed apron. Made from soft cotton, it’s both comfortable and stylish, perfect for all your summer cooking adventures.',
  },
  {
    id: 7,
    name: 'Whiskey Dispenser With RGB Lighting',
    price: '89.00',
    image: image7,
    badge: 'Exclusive',
    description: 'Impress your guests with this innovative whiskey dispenser. Featuring RGB lighting, it adds a modern touch to any bar setup while ensuring a perfect pour every time.',
  },
  {
    id: 8,
    name: 'Custom Wooden Whiskey Box',
    price: '39.91',
    image: image8,
    badge: 'Exclusive',
    description: 'This custom wooden whiskey box is the perfect gift for any whiskey lover. Its handcrafted design provides both elegance and practicality, ideal for storing and showcasing fine whiskey.',
  },

];

export default products;
