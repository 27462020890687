import React, { createContext, useContext } from 'react';

const SiteContext = createContext();

export const SiteProvider = ({ children }) => {
  const siteInfo = {
    siteName: "RazorOlympus.com",
    slogan: "Cutting Edge Excellence",
    info: "Discover the finest cutlery crafted for precision and durability. Elevate your culinary experience with our premium knives and kitchen tools.",
    thankYouProduct: "Thank you for your interest in our products. We hope you find the perfect cutlery for your needs.",
    thankYouSubscription: "Thank you for subscribing to RazorOlympus. Stay tuned for updates and exclusive offers.",
    thankYouOrder: "Thank you for your order. Your new cutlery is on its way to enhance your culinary adventures.",
  };

  return (
    <SiteContext.Provider value={siteInfo}>
      {children}
    </SiteContext.Provider>
  );
};

// Create a custom hook to use the SiteContext
export const useSite = () => {
  return useContext(SiteContext);
};
